import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CourseList = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/courses`);
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  return (
    <div className="container mx-auto px-4 py-12" id="courses">
      <div>
        <h2 className="text-3xl font-bold text-center mb-8">Explore Our Courses</h2>
        <div className="space-y-8">
          {courses.map((course) => (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden p-6 border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl" key={course.slug}>
              <div className="md:flex">
                <img src={course.image_url} alt={course.name} className="md:w-1/2 h-64 md:h-auto object-cover" />
                <div className="p-6 md:w-1/2">
                  <h3 className="text-xl font-bold mb-2">{course.name}</h3>
                  <p className="text-gray-700 leading-relaxed mb-4">{course.description}</p>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-gray-500 text-sm mb-2">Duration</p>
                      <p className="font-bold mb-2">{course.duration}</p>
                    </div>
                    <div>
                      <p className="text-gray-500 text-sm mb-2">Highest Salary</p>
                      <p className="font-bold mb-2">{course.salary_range} LPA</p>
                    </div>
                  </div>
                  <div className="mt-4">
                    {course.status === 'upcoming' ? (
                      <button className="bg-gray-400 text-white px-6 py-3 rounded text-sm font-medium cursor-not-allowed w-full">
                        Coming Soon
                      </button>
                    ) : (
                      <a href={`/course/${course.slug}`} className="inline-block bg-indigo-600 text-white px-6 py-3 rounded-md text-lg font-medium transition duration-300 ease-in-out transform hover:bg-indigo-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full text-center">
                        Know More
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseList;
