import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout'; // Import your Layout component
import Home from './components/Home';
import CourseDetails from './components/CourseDetails';
import TermsAndConditions from './components/TermsAndConditions';
import CancellationsAndRefundPolicy from './components/CancellationsAndRefundPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="course/:slug" element={<CourseDetails />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="cancellations-and-refund" element={<CancellationsAndRefundPolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
