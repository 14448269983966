import React from 'react';
import logo from '../logo.png'; // Import your logo image

const CompanyOverview = () => {
  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center flex flex-col items-center">
        <img
          src={logo} // Use the imported logo
          alt="Brainflyers Logo"
          className="w-80 h-auto mb-6" // Adjusted width
        />
        <p className="text-gray-800 text-2xl font-semibold mb-6">Unlock Your Potential, Design Your Future</p>
        <p className="text-gray-700 mb-6">
          Empowering you with the skills to excel in today's job market through innovative online courses.
        </p>
        <p className="text-gray-700 mb-8">
          Join our community and turn your aspirations into reality with expert guidance and hands-on projects.
        </p>
        <a
          href="#courses"
          className="inline-block bg-indigo-600 text-white px-6 py-3 rounded-md text-lg font-medium transition duration-300 ease-in-out transform hover:bg-indigo-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Explore Our Courses
        </a>
      </div>
    </div>
  );
};

export default CompanyOverview;
