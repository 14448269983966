import ProgramFeatures from './ProgramFeatures';
import CompanyOverview from './CompanyOverview';
import Courses from './Courses';
import Testimonials from './Testimonials';

function Home() {
  return (
    <div className="Home">
      <CompanyOverview />
      <Testimonials />
      <ProgramFeatures />
      <Courses />
    </div>
  );
}

export default Home;
