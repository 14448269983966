import React, { useState, useEffect } from 'react';

import RuchaImage from '../images/rucha.png';
import AkshayImage from '../images/akshay.png';
import KrishnaKumariImage from '../images/krishna-kumari.png';
import ChetanImage from '../images/chetan.png';

// Sample testimonials data
const testimonialsData = [
  {
    name: "Rucha",
    designation: "UI/UX Designer",
    testimonial: "Brainflyers has transformed my learning experience. The courses are well-structured and informative.",
    photo: RuchaImage,
  },
  {
    name: "Akshay",
    designation: "Web Developer",
    testimonial: "The instructors are knowledgeable and supportive. I've gained valuable skills that I can apply in my career.",
    photo: AkshayImage,
  },
  {
    name: "Krishna Kumari",
    designation: "SAP FICO Analyst",
    testimonial: "The courses at Brainflyers have been a game changer for my career. The practical approach and real-world examples helped me apply what I learned immediately. Highly recommended!",
    photo: KrishnaKumariImage,
  },
  {
    name: "Chetan",
    designation: "Cyber Security Expert",
    testimonial: "I highly recommend Brainflyers! The content is engaging, and I've seen a significant improvement in my skills.",
    photo: ChetanImage,
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const { name, designation, testimonial, photo } = testimonialsData[currentIndex];

  return (
    <div className="flex justify-center items-center">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-3xl flex fixed-height">
        <img src={photo} alt={name} className="w-32 h-32 rounded-full mr-6" />
        <div className="flex-1">
          <h3 className="text-2xl font-bold text-gray-800">{name}</h3>
          <p className="text-gray-600 italic">{designation}</p>
          <p className="mt-4 text-gray-700 line-clamp">{testimonial}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
