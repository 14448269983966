import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  // Scroll to top when the component is rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>

      <p>
        Effective Date: September 16, 2024
      </p>
      <p>
        This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website brainflyers.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto.
      </p>

      <h2 className="text-2xl font-semibold mt-4">1. Information We Collect</h2>
      <p>
        We may collect personal information about you in a variety of ways, including when you visit our site, register on the site, place an order, subscribe to our newsletter, respond to a survey, fill out a form, or engage with other activities, services, features, or resources we make available on our site.
      </p>
      <p>
        The personal information we may collect includes:
      </p>
      <ul className="list-disc ml-6">
        <li>Name</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>Mailing Address</li>
        <li>Payment Information (if applicable)</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4">2. How We Use Your Information</h2>
      <p>
        We may use the information we collect from you in the following ways:
      </p>
      <ul className="list-disc ml-6">
        <li>To improve customer service.</li>
        <li>To personalize user experience.</li>
        <li>To process transactions.</li>
        <li>To send periodic emails regarding your order or other products and services.</li>
        <li>To administer a contest, promotion, survey or other site feature.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4">3. Disclosure of Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you so long as those parties agree to keep this information confidential.
      </p>

      <h2 className="text-2xl font-semibold mt-4">4. Data Security</h2>
      <p>
        We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our site.
      </p>

      <h2 className="text-2xl font-semibold mt-4">5. Your Rights</h2>
      <p>
        Under Indian law, you have the right to:
      </p>
      <ul className="list-disc ml-6">
        <li>Access your personal data.</li>
        <li>Request correction of your personal data if it is inaccurate or incomplete.</li>
        <li>Request deletion of your personal data.</li>
        <li>Object to the processing of your personal data.</li>
        <li>Request the transfer of your personal data to another party.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4">6. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site.
      </p>

      <h2 className="text-2xl font-semibold mt-4">7. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or our practices regarding your personal information, please contact us:</p>
      <p>Email: support@brainflyers.com</p>

    </div>
  );
};

export default PrivacyPolicy;
