import React, { useEffect } from 'react';

const CancellationsAndRefundPolicy = () => {
  // Scroll to top when the component is rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // This effect runs only once when the component mounts

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-6">Cancellations and Refund Policy</h1>

      <h2 className="text-2xl font-semibold mt-4">1. Overview</h2>
      <p>
        At Brainflyers, we strive to provide high-quality educational services. However, due to the nature of our offerings, we have a strict policy regarding cancellations and refunds.
      </p>

      <h2 className="text-2xl font-semibold mt-4">2. No Refunds</h2>
      <p>
        All purchases made on our platform are final. Once a transaction is completed, no refunds will be issued under any circumstances. This includes, but is not limited to, the following:
      </p>
      <ul className="list-disc ml-6">
        <li>Course fees</li>
        <li>Subscription fees</li>
        <li>Any other fees associated with our services</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4">3. No Cancellations</h2>
      <p>
        Users are not permitted to cancel any courses or subscriptions once the purchase has been made. We encourage users to review their choices carefully before completing the transaction.
      </p>

      <h2 className="text-2xl font-semibold mt-4">4. Exceptions</h2>
      <p>
        Due to the nature of our services, we do not offer exceptions to this policy. We recommend that users take advantage of any free trials or previews we may offer to assess our services before making a purchase.
      </p>

      <h2 className="text-2xl font-semibold mt-4">5. Contact Information</h2>
      <p>
        If you have any questions regarding our Cancellations and Refund Policy, please contact us at <a href="mailto:support@brainflyers.com" className="text-blue-600">support@brainflyers.com</a>.
      </p>
    </div>
  );
};

export default CancellationsAndRefundPolicy;
