import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const BottomBar = () => {
  return (
    <footer className="BottomBar bg-gray-800 text-white p-4 text-center">
      <p>&copy; {new Date().getFullYear()} VKH TECHNOLOGIES AND SERVICES LLP. All rights reserved.</p>
      <p>
        <Link to="/terms-and-conditions" className="text-blue-400 hover:underline mr-4">
          Terms and Conditions
        </Link>
        <Link to="/privacy-policy" className="text-blue-400 hover:underline ml-4">
          Privacy Policy
        </Link>
        <Link to="/cancellations-and-refund" className="text-blue-400 hover:underline ml-4">
          Cancellations and Refund
        </Link>
      </p>
    </footer>
  );
};

export default BottomBar;
