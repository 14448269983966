import React from 'react';
import { SocialIcon } from 'react-social-icons';

const SocialMediaLinks = () => {
  return (
    <div className="text-center mb-8">
      <h3 className="text-2xl font-semibold mb-4">Follow Us On</h3>
      <div className="flex justify-center space-x-4">
        <SocialIcon url="https://www.linkedin.com/company/brainflyers/" target="_blank" fgColor="#fff" bgColor="#0077b5" />
        <SocialIcon url="https://www.instagram.com/brainflyers?igsh=NXFqOXEzNnhpNTFm" target="_blank" fgColor="#fff" bgColor="#E1306C" />
        <SocialIcon url="https://www.facebook.com/profile.php?id=61564049235595" target="_blank" fgColor="#fff" bgColor="#3b5998" />
        <SocialIcon url="https://www.youtube.com/@brainflyers" target="_blank" fgColor="#fff" bgColor="#FF0000" />
      </div>
    </div>
  );
};

export default SocialMediaLinks;
