import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Rocket, ChevronDown, ChevronUp, BookOpen } from 'lucide-react';

const CourseDetail = () => {
  const { slug } = useParams();
  const [course, setCourse] = useState(null);
  const [expandedModule, setExpandedModule] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/courses/${slug}?slug=${slug}`);
        setCourse(response.data);
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };

    fetchCourseDetails();
  }, [slug]);

  const toggleModule = (index) => {
    setExpandedModule(expandedModule === index ? null : index);
  };

  if (!course) return <div className="text-center py-12">Loading...</div>;

  return (
    <div className="container mx-auto px-6 py-12">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden p-8 border border-gray-200">
        <img src={course.image_url} alt={course.name} className="w-full h-72 object-cover mb-6 rounded-lg" />
        <h2 className="text-4xl font-bold mb-3 text-gray-900">{course.name}</h2>
        <p className="text-gray-700 mb-5 text-base">{course.description}</p>

        <div className="flex justify-between mb-8">
          <span className="text-xl font-bold text-black-600">
            <span>Price: </span>
            <span className="text-green-500">₹{course.discounted_price}</span>
            <span className="ml-2 line-through text-gray-500">₹{course.price}</span>
          </span>
          <span className="text-base font-semibold text-gray-600">Duration: {course.duration}</span>
        </div>

        <h3 className="text-3xl font-semibold mt-8 mb-4 text-gray-900">Course Modules</h3>
        {course.course_modules.map((module, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between items-center cursor-pointer py-4 hover:bg-gray-100 transition duration-200" onClick={() => toggleModule(index)}>
              <div className="flex items-center">
                <Rocket className="h-5 w-5 text-indigo-600 mr-2" />
                <h4 className="text-xl font-bold text-gray-800">
                  {module.name}
                  <span className="text-gray-500 text-base ml-3">({module.duration} min, {module.course_chapters.length} lessons)</span>
                </h4>
              </div>
              <span className="text-gray-500 text-xl">
                {expandedModule === index ? <ChevronUp className="h-6 w-6" /> : <ChevronDown className="h-6 w-6" />}
              </span>
            </div>
            {expandedModule === index && (
              <div className="px-6 pb-6">
                <h5 className="text-xl font-semibold mt-4 text-gray-800 mb-4">Chapters:</h5>
                <div className="flex flex-col gap-4">
                  {module.course_chapters.map((chapter, chapterIndex) => (
                    <div key={chapterIndex} className="bg-gray-100 p-6 rounded-lg shadow-md flex items-start hover:shadow-lg transition duration-200">
                      <BookOpen className="h-5 w-5 text-blue-600 mr-4" />
                      <div className="flex-1">
                        <strong className="text-gray-800 text-base mb-4">{chapter.name}</strong>
                        <p className="text-gray-600 text-sm mt-4 mb-4">{chapter.description}</p>
                        <p className="text-gray-500 text-sm mb-4">Duration: {chapter.duration} minutes</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseDetail;
