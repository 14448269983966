import React from 'react';
import logo from '../logo.png';

const AboutUs = () => {
  return (
    <div className="bg-white py-12" id="aboutus">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="md:col-span-1">
            <h2 className="text-3xl font-bold mb-4">About Brainflyers</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Brainflyers is an edtech company dedicated to empowering individuals to achieve their career goals. We offer comprehensive online courses that provide extensive domain knowledge, practical experience, and personalized guidance to help you land your dream job or switch careers successfully.
            </p>
            <p className="text-gray-700 leading-relaxed mb-4">
              Our mission is to bridge the gap between education and employment, ensuring that our students are well-equipped with the skills and knowledge required by industry leaders. We believe in the power of education to transform lives and create a better future for all.
            </p>
          </div>
          <div className="md:col-span-1 flex items-center justify-center">
            <img src={logo} alt="Brainflyers" className="w-full max-w-md rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
