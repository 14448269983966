import React from 'react';

import extensiveKnowledge from '../images/extensive-knowledge.jpg';
import realWorldAssignments from '../images/real-world-assignments.jpg';
import mockInterviews from '../images/mock-interviews.jpg';
import jobAssistance from '../images/job-assistance.jpg';
import curatedMaterials from '../images/curated-materials.jpg';
import certification from '../images/certification.jpg';

const ProgramFeatures = () => {
  const features = [
    {
      title: "In-Depth Expertise",
      description: "Gain in-depth understanding of your chosen field through comprehensive courses designed by industry experts.",
      image: extensiveKnowledge
    },
    {
      title: "Real World Assignments",
      description: "Engage in hands-on projects that simulate real-world challenges and enhance your practical skills.",
      image: realWorldAssignments
    },
    {
      title: "Mock Interviews",
      description: "Prepare for job interviews with realistic simulations and receive feedback to improve your performance.",
      image: mockInterviews
    },
    {
      title: "Job Assistance",
      description: "Get personalized support in finding job opportunities that match your skills and aspirations.",
      image: jobAssistance
    },
    {
      title: "Curated Materials",
      description: "Access a wealth of curated resources to improve your problem-solving and communication skills.",
      image: curatedMaterials
    },
    {
      title: "Certification",
      description: "Access a wealth of curated resources to improve your problem-solving and communication skills.",
      image: certification
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-3xl font-bold text-center mb-8">How Brainflyers Can Guide You to Your Ideal Job</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden" key={index}>
            <img src={feature.image} alt={feature.title} className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-700 leading-relaxed mb-4">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramFeatures;
