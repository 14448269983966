// Layout.js
import React from 'react';
import TopBar from './TopBar';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import BottomBar from './BottomBar';
import SocialMediaLinks from './SocialMediaLinks';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className="Layout">
      <TopBar />
      <main>
        <Outlet />  {/* This is where the child routes will be rendered */}
      </main>
      <ContactUs />
      <AboutUs />
      <SocialMediaLinks />
      <BottomBar />
    </div>
  );
};

export default Layout;
