import React, { useEffect } from 'react';

const TermsAndConditions = () => {
  // Scroll to top when the component is rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // This effect runs only once when the component mounts

  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-6">Terms and Conditions</h1>

      <h2 className="text-2xl font-semibold mt-4">1. Introduction</h2>
      <p>
        These Terms and Conditions govern your use of the Brainflyers website and services provided by VKH TECHNOLOGIES AND SERVICES LLP, whose registered office is located at W No 30, H No 315/10, Opp K V School, Dollors Colony, 2nd Cross, Bandihatti Ballari, KARNATAKA 583104. By accessing our website and/or purchasing from us, you agree to comply with these Terms and Conditions.
      </p>

      <h2 className="text-2xl font-semibold mt-4">2. Definitions</h2>
      <p>
        For the purpose of these Terms, "we", "us", and "our" refer to VKH TECHNOLOGIES AND SERVICES LLP. "You", "your", "user", and "visitor" refer to any natural or legal person visiting our website and/or agreeing to purchase from us.
      </p>

      <h2 className="text-2xl font-semibold mt-4">3. User Obligations</h2>
      <p>
        You agree to use our services only for lawful purposes and in a manner that does not infringe the rights of others. You shall not engage in any conduct that restricts or inhibits anyone's use or enjoyment of the website. Prohibited activities include, but are not limited to:
      </p>
      <ul className="list-disc ml-6">
        <li>Using the website for any illegal or unauthorized purpose.</li>
        <li>Attempting to gain unauthorized access to any portion of the website or any other systems or networks connected to the website.</li>
        <li>Transmitting any viruses, malware, or harmful code.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4">4. Intellectual Property</h2>
      <p>
        All content on our website, including but not limited to text, graphics, logos, and software, is the property of VKH TECHNOLOGIES AND SERVICES LLP or its licensors and is protected by copyright and other intellectual property laws. Unauthorized reproduction or distribution of any content is prohibited.
      </p>

      <h2 className="text-2xl font-semibold mt-4">5. Payment Terms</h2>
      <p>
        All fees for services are due upon registration. We reserve the right to change our pricing at any time. Refunds may be issued at our discretion and are subject to our refund policy, which allows for refunds within 14 days of purchase under specific conditions.
      </p>

      <h2 className="text-2xl font-semibold mt-4">6. Limitation of Liability</h2>
      <p>
        Neither VKH TECHNOLOGIES AND SERVICES LLP nor any third parties provide any warranty or guarantee regarding the accuracy, timeliness, performance, completeness, or suitability of the information and materials found on this website. You acknowledge that such information may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
      </p>
      <p>
        Your use of any information or materials on our website is entirely at your own risk, and we shall not be liable for any loss or damage arising from your use of the website.
      </p>

      <h2 className="text-2xl font-semibold mt-4">7. Governing Law</h2>
      <p>
        These Terms are governed by the laws of India. Any disputes arising out of your use of the website or any transactions with us shall be subject to the exclusive jurisdiction of the courts located in Karnataka, India.
      </p>

      <h2 className="text-2xl font-semibold mt-4">8. Third-Party Links</h2>
      <p>
        Our website may contain links to other websites for your convenience. We do not endorse or assume any responsibility for the content of these linked sites. You access these sites at your own risk.
      </p>

      <h2 className="text-2xl font-semibold mt-4">9. Amendments</h2>
      <p>
        We reserve the right to update these Terms at any time. Changes will be posted on this page, and it is your responsibility to review these Terms periodically. Your continued use of the website after any changes constitutes your acceptance of the new Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-4">10. Contact Information</h2>
      <p>
        If you have any questions about these Terms, please contact us at <a href="mailto:support@brainflyers.com" className="text-blue-600">support@brainflyers.com</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;
