import React, { useState } from 'react';
import axios from 'axios';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [course, setCourse] = useState('Not Sure'); // State for course selection
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const mobileRegex = /^[0-9]{10}$/; // Simple regex for 10-digit mobile number

    if (!name) {
      newErrors.name = 'Name is required';
    }
    if (!mobile) {
      newErrors.mobile = 'Mobile number is required';
    } else if (!mobileRegex.test(mobile)) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if there are validation errors
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contact`, {
        name,
        mobile,
        email,
        course_interested: course, // Include selected course in the submission
      });

      if (response.status === 200) {
        setShowModal(true);
        // Reset form fields
        setName('');
        setMobile('');
        setEmail('');
        setCourse('Not Sure'); // Reset course selection
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <div id="contact" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-start justify-between">
          {/* Contact Details Section */}
          <div className="lg:w-1/2 mb-8 lg:mb-0 lg:mr-12">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">Contact Us</h2>
            <div className="flex flex-col space-y-4">
              <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-full">
                <p className="font-medium text-gray-800">Email</p>
                <p className="text-indigo-600">
                  <a href="mailto:contact@brainflyers.com" className="hover:underline">contact@brainflyers.com</a>
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-full">
                <p className="font-medium text-gray-800">Phone</p>
                <p className="text-gray-800">
                  <a href="tel:+918050655560" className="text-indigo-600 hover:underline">+91 8050655560</a>
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-full">
                <p className="font-medium text-gray-800">Phone</p>
                <p className="text-gray-800">
                  <a href="tel:+919019701969" className="text-indigo-600 hover:underline">+91 9019701969</a>
                </p>
              </div>
            </div>
          </div>

          {/* Form Section */}
          <div className="lg:w-1/2 bg-white p-8 rounded-lg shadow-lg border border-gray-200">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Need help? <br/><br/> Our Counselors are LIVE right now!</h3>
            <form onSubmit={handleSubmit} className="flex flex-col">
              {/* Name Input */}
              <label className="text-gray-700 mb-1" htmlFor="name">Your Name</label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className={`border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md px-4 py-2 mb-4 focus:outline-none focus:ring focus:ring-indigo-600 transition duration-200`}
              />
              {errors.name && <p className="text-red-500 text-sm mb-2">{errors.name}</p>}

              {/* Mobile Input */}
              <label className="text-gray-700 mb-1" htmlFor="mobile">Mobile Number</label>
              <input
                id="mobile"
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
                className={`border ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded-md px-4 py-2 mb-4 focus:outline-none focus:ring focus:ring-indigo-600 transition duration=200`}
              />
              {errors.mobile && <p className="text-red-500 text-sm mb=2">{errors.mobile}</p>}

              {/* Email Input */}
              <label className="text-gray700 mb=1" htmlFor="email">Email Address</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={`border ${errors.email ? 'border-red500' : 'border-gray300'} rounded-md px-4 py-2 mb-6 focus:outline-none focus:ring=2 focus:ring-indigo600 transition duration=200`}
              />
              {errors.email && <p className="text-red500 text-sm mb=2">{errors.email}</p>}

              {/* Dropdown for Course Selection */}
              <label className="text-gray-700 mb-1" htmlFor="course">Course Interested</label>
              <div className="relative mb-4">
                <select
                  id="course"
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-600 transition duration-200"
                >
                  <option value="Not Sure">Not Sure</option>
                  <option value="Cybersecurity">Cybersecurity</option>
                  <option value="SAP">SAP</option>
                  <option value="App Development">App Development</option>
                  <option value="Android Development">Android Development</option>
                  <option value="IOS Development">IOS Development</option>
                  <option value="Web Development">Web Development</option>
                  <option value="Data Science">Data Science</option>
                  <option value="AI & ML">AI & ML</option>
                  <option value="Devops">Devops</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Project Management">Project Management</option>
                  <option value="Manual & Automation Testing">Manual & Automation Testing</option>
                  <option value="Placement Training and Mock Interviews">Placement Training and Mock Interviews</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  {/* Chevron Icon */}
                  <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-indigo-600 text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-indigo-700 transition duration-300 mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Modal for thank you message */}
      {showModal && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='bg-white p-6 rounded-md shadow-md text-center'>
            <h3 className='text-lg font-semibold mb-4'>Thank You!</h3>
            <p>We will get in touch with you soon.</p>
            <button
              onClick={() => setShowModal(false)}
              className='mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300'
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
